import React from "react";

import { Box, Typography, TextField, Button, styled } from "@mui/material";
import { AppLogo, FormLogo, helpIcon } from "./assets";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ForgotPasswordOTPController, {
	Props,
} from "./ForgotPasswordOTPController";
import { closeIcon, successIcon } from "./assets";

const ids = {
	closeModal: 'close-modal',
	otpResend: 'otp-resend',
	otpInput: 'otp-input',
	submitBtn: 'submit-btn',
	continueBtn: 'continue-btn',
};

export default class ForgotPasswordOTP extends ForgotPasswordOTPController {
	constructor(props: Props) {
		super(props);
	}

	OtpBlock = () => {
		return (
			<Box style={webStyle.boxStyle}>
				<Box sx={webStyle.otpContainer}>
					<Box display="flex" alignItems="center" gap="40px">
						<Box display="flex" alignItems="center" gap="16px">
							{this.state.otp.slice(0, 3).map((digit, index) => (
								<TextField
									data-test-id={ids.otpInput}
									sx={webStyle.otpInput}
									key={index}
									value={digit}
									inputProps={{ maxLength: 1 }}
									placeholder="#"
									onChange={(e) => this.handleChange(e.target.value, index)}
									onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => this.handleKeyDown(e, index)}
									inputRef={this.otpBoxReference[index]}
								/>
							))}
						</Box>
						<Box display="flex" alignItems="center" gap="16px">
							{this.state.otp.slice(3).map((digit, index) => (
								<TextField
									data-test-id={ids.otpInput}
									sx={webStyle.otpInput}
									key={index + 3}
									value={digit}
									inputProps={{ maxLength: 1 }}
									placeholder="#"
									onChange={(e) => this.handleChange(e.target.value, index + 3)}
									onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => this.handleKeyDown(e, index + 3)}
									inputRef={this.otpBoxReference[index + 3]}
								/>
							))}
						</Box>
					</Box>
					{this.state.otpMessage && (
						<Typography sx={{ ...webStyle.otpMessage, color: this.state.isOtpErrorMessage ? '#DC2626' : '#059669' }}>
							{this.state.otpMessage}
						</Typography>
					)}
				</Box>
				{(this.state.otpMessage && this.state.isOtpErrorMessage) && <img style={{ marginTop: '17.5px' }} src={closeIcon} alt="error" />}
			</Box>
		);
	};

	renderOtpBlock = () => !this.state.isSuccess ? this.OtpBlock() : null;

	ResendOtpBlock = () => {
		return (
			<Typography data-test-id={ids.otpResend} sx={webStyle.resendSecurityCode} onClick={this.handleResendOtp}>
				Resend security code
			</Typography>
		);
	}

	renderResendOtpBlock = () => !this.state.isSuccess ? this.ResendOtpBlock() : null;

	SuccessBlock = () => {
		return (
			<Box
				display="flex"
				alignItems="center"
				justifyContent="center"
				marginTop="49px"
				marginBottom="39px"
			>
				<img src={successIcon} alt="success" />
			</Box>
		);
	}

	renderSuccessBlock = () => this.state.isSuccess ? this.SuccessBlock() : null;

	SendRequestBlock = () => {
		return (
			<Button
				data-test-id={ids.submitBtn}
				style={{
					...webStyle.acceptBtn,
					...(this.state.activeBtn ? webStyle.acceptBtnCompleted : {}),
				}}
				onClick={this.handleOtpSubmit}
				disabled={!this.state.activeBtn}
			>
				Send
			</Button>
		);
	};

	ContinueBlock = () => {
		return (
			<Button
				data-test-id={ids.continueBtn}
				style={{ ...webStyle.acceptBtn, ...webStyle.acceptBtnCompleted }}
				onClick={this.handleContinue}
			>
				Continue
			</Button>
		);
	};

	renderButton = () => this.state.isSuccess ? this.ContinueBlock() : this.SendRequestBlock();

	render() {
		return (
			<>
				<BoxContainer fontFamily="Inter, Arial, Helvetica, sans-serif" fontStyle='inter'>
					<ToastContainer style={{ maxWidth: '607px', minWidth: "fit-content" }} />
					<FormBoxContainer>
						<img src={AppLogo.default} style={{ width: "436px", height: "102px" }} />
					</FormBoxContainer>
					<FormBoxContainer width="50%">
						<Box
							width="586px"
							height="722px"
							bgcolor="rgba(235, 235, 235, 0.898)"
							borderRadius="16px"
							border="3px solid white"
							display='flex'
							flexDirection='column'
							justifyContent='start'
							alignItems='center'
						>
							<Box alignItems="center" display="flex" flexDirection="column">
								<Box display="flex" justifyContent="space-between" alignItems="center" width="586px" >
									<ChevronLeftIcon data-test-id={ids.closeModal} onClick={this.closeModal} style={{ fontSize: '2.5rem', marginBottom: '16px' }} />
									<Box display="flex" justifyContent="end" alignItems="center" marginTop='15px' marginRight='15px' marginBottom='30px' width="586px">
										<img src={helpIcon.default} />
									</Box>
								</Box>
								<Box display="flex" justifyContent="center" width="100%" >
									<img src={FormLogo.default} />
								</Box>
							</Box>
							<Typography style={webStyle.logoText}>Forgot password</Typography>
							{this.state.isSuccess ? (
								<Typography style={webStyle.signupText}>
									you have entered your security code <br /> correctly.
								</Typography>
							) : (
								<Typography style={webStyle.signupText}>
									Please enter the security code that was <br /> sent to your email address.
								</Typography>
							)}

							{this.renderOtpBlock()}

							{this.renderResendOtpBlock()}

							{this.renderSuccessBlock()}

							{this.renderButton()}
						</Box>
					</FormBoxContainer>

				</BoxContainer>
			</>
		);
	}
}

const BoxContainer = styled(Box)({
	flex: 1,
	height: "100%",
	alignItems: "center",
	width: "100%",
	backgroundColor: "#00184C",
	display: "flex",

	"@media (max-width: 1281px)": {
	  gap: "30px",
	  paddingTop: "30px",
	  flexDirection: "column",
	},
  })
  
  // fix bugs related to UI of other experts.
  const FormBoxContainer = styled(Box)({
	display: "flex",
	flex: 1,
	alignItems: "center", 
	justifyContent: "center",
	
	"@media (max-height: 803px)": {
	  scale: 0.95
	},
	"@media (max-height: 753px), (max-width: 753px)": {
	  scale: 0.9
	},
	"@media (max-height: 703px), (max-width: 703px)": {
	  scale: 0.85
	},
	"@media (max-height: 653px), (max-width: 653px)": {
	  scale: 0.75
	},
  });

const webStyle = {
	boxStyle: {
		display: "flex",
		alignItems: 'flex-start',
		gap: '16px',
		marginTop: '64px'
	},
	otpContainer: {
		display: "flex",
		flexDirection: 'column',
		alignItems: 'flex-end',
		justifyContent: 'center',
		gap: '8px',
	},
	otpInput: {
		width: '46px',
		'& .MuiInputBase-input': {
			fontSize: '30px',
			fontFamily: '400',
			color: '#0F172A',
			width: '46px',
			padding: '8px 14px',
			backgroundColor: '#FFFFFF',
			borderRadius: '4.6px',
			boxShadow: '0px 1px 0px 0px #0000004D',
			borderColor: '#FFFFFF',
		},
		'& .MuiInputBase-input::placeholder': {
			color: '#64748B',
		},
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: '#FFFFFF',
			},
			'&:hover fieldset': {
				borderColor: '#FFFFFF',
			},
			'&.Mui-focused fieldset': {
				borderColor: '#FFFFFF',
				borderWidth: '1px',
			},
		},
	},
	logoText: {
		fontWeight: 700,
		fontSize: '30px',
		paddingTop: '35px',
		paddingBottom: '8px',
		height: "40px",
		color: "#22223E",
		textAlign: 'center' as 'center',
		fontStyle: 'inter',
	},
	signupText: {
		color: '#334155',
		textAlign: 'center' as 'center',
		fontSize: "16px",
		fontStyle: 'inter',
		fontWeight: 400,
		lineHeight: '24px',

	},
	helpIcon: {
		position: "absolute" as "absolute",
		right: "18.33px",
		top: "20.33px",
		width: "23.33px",
		height: '23.33px'
	},
	textInput: {
		border: "1px solid #334155",
		backgroundColor: "white",
		borderRadius: "8px"
	},
	labelStyle: {
		color: '#334155',
		fontSize: '14px',
		fontWeight: 700 as 700,
		paddingBottom: '4px',
	},
	acceptBtn: {
		width: '207',
		height: '44px',
		border: '2px solid #00184C',
		borderRadius: '24px',
		color: '#00184C',
		textContent: 'center',
		fontWeight: 700 as 700,
		textTransform: "capitalize" as "capitalize"
	},
	acceptBtnCompleted: {
		backgroundColor: '#00184C',
		color: 'white'
	},
	otpMessage: {
		fontSize: '14px',
		fontWeight: '400',
	},
	resendSecurityCode: {
		fontSize: '14px',
		fontWeight: '700',
		color: '#1E293B',
		textDecoration: 'underline',
		marginBlock: '40px',
	},
}
