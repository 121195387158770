// Customizable Area Start
export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/down.png");
export const ic_plus = require("../assets/ic_plus.png");
export const ic_back = require("../assets/ic_back.png");
export const ic_user = require("../assets/ic_user.png");
export const ic_admin = require("../assets/ic_admin.png");
export const ic_success = require("../assets/ic_success.png");
export const ic_success_delete = require("../assets/ic_success_delete.png");
export const ic_pending = require("../assets/ic_pending.png");
// Customizable Area End