import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
	getName
} from "../../../framework/src/Messages/MessageEnum";
import React, { createRef, RefObject } from "react";
import { Message } from "../../../framework/src/Message";
import 'react-toastify/dist/ReactToastify.css';
import { toast } from "react-toastify";
import { closeSmallIcon } from "./assets";

export const configJSON = require("./config");
const numberOfDigits = 6;

export interface Props {
	navigation: any;
	id: string;
}

export interface S {
	email: any;
	activeBtn: boolean;
	token: string | null;
	isSuccess: boolean;
	otp: string[];
	otpMessage: string | null;
	isOtpErrorMessage: boolean;
}

export interface SS {
	id: any;
}

export default class ForgotPasswordOTPController extends BlockComponent<
	Props,
	S,
	SS
> {

	apiSentOtp: string = '';
	apiResendOtp: string = '';
	apiConfirmOtp: string = '';
	apiPrivatePolicy: string = '';
	otpToken: string = '';
	otpBoxReference: RefObject<HTMLInputElement>[];

	constructor(props: Props) {
		super(props);
		this.subScribedMessages = [
			getName(MessageEnum.RestAPIResponceMessage),
			getName(MessageEnum.NavigationPayLoadMessage),
			getName(MessageEnum.CountryCodeMessage)
		];
		this.receive = this.receive.bind(this);

		runEngine.attachBuildingBlock(this, this.subScribedMessages);

		this.state = {
			email: '',
			activeBtn: false,
			token: null,
			otp: Array(numberOfDigits).fill(""),
			otpMessage: null,
			isOtpErrorMessage: false,
			isSuccess: false,
		};

		this.otpBoxReference = Array(numberOfDigits).fill(null).map(() => createRef());
	}

	handleChange = (value: string, index: number) => {
		if (!/^\d?$/.test(value)) return;

		const newOtp = [...this.state.otp];
		newOtp[index] = value;
		const isActive = newOtp.every((digit) => digit !== "");
		this.setState({ otp: newOtp, activeBtn: isActive, otpMessage: null }, () => {
			if (value && index < numberOfDigits - 1) {
				this.otpBoxReference[index + 1].current?.focus();
			}
		});
	};

	handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
		if (e.key === "Backspace" && !this.state.otp[index] && index > 0) {
			this.otpBoxReference[index - 1].current?.focus();
		} else if (e.key === "Enter" && index < numberOfDigits - 1) {
			if (this.state.otp[index]) {
				this.otpBoxReference[index + 1].current?.focus();
			}
		}
	};

	handleOtpSubmit = () => {
		const otpCode = this.state.otp.join("");

		const header = {
			"Content-Type": "application/json"
		};

		const httpBody = {
			data: {
				otp_code: otpCode,
				token: this.state.token
			}
		}

		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);
		this.apiConfirmOtp = requestMessage.messageId;
		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			configJSON.otpConfirmApiEndPoint
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			JSON.stringify(httpBody)
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.httpPostMethod
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
	};

	handleResendOtp = () => {
		const header = {
			"Content-Type": "application/json"
		};

		const httpBody = {
			email: this.state.email,
		}

		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);
		this.apiResendOtp = requestMessage.messageId;
		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			configJSON.otpResendApiEndPoint
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			JSON.stringify(httpBody)
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.httpPostMethod
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
	}

	async receive(from: string, message: Message) {
		if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
			const otpAuthTkn = message.getData(
				getName(MessageEnum.AuthTokenDataMessage)
			);
			const otpEmail = message.getData(getName(MessageEnum.AuthTokenEmailMessage));

			if (otpAuthTkn && otpAuthTkn.length > 0) {
				this.setState({ token: otpAuthTkn, email: otpEmail }, () => {
					this.handleShowNotification();
				});
				this.otpToken = this.state.token as string;
			}
		}

		if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
			const apiRequestCallId = message.getData(
				getName(MessageEnum.RestAPIResponceDataMessage)
			);

			const responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);

			if (responseJson && responseJson.data !== undefined) {
				if (apiRequestCallId === this.apiResendOtp) {
					this.handleResendOtpSuccessfulCallback(responseJson);
				}
			} else if (responseJson && responseJson.messages !== undefined) {
				if (apiRequestCallId === this.apiConfirmOtp) {
					this.handleConfirmOtpSuccessfulCallback();
				}
			} else if (responseJson && responseJson.errors !== undefined) {
				if (apiRequestCallId === this.apiConfirmOtp) {
					this.handleConfirmOtpFailureCallback(responseJson);
				}
			}
		}
	}

	handleConfirmOtpSuccessfulCallback = () => {
		this.setState({ isSuccess: true, otpMessage: null });
	}

	handleConfirmOtpFailureCallback = (responseJson: any) => {
		if (
			responseJson.errors[0].otp !== undefined
			&& responseJson.errors[0].otp === 'Invalid OTP code'
		) {
			this.setState({ otpMessage: configJSON.otpMatchErrorText, activeBtn: false, isOtpErrorMessage: true });
		} else if (
			responseJson.errors[0].pin !== undefined
			&& responseJson.errors[0].pin === "OTP has expired, please request a new one."
		) {
			this.setState({ otpMessage: configJSON.otpExpiredText, activeBtn: false, isOtpErrorMessage: true });
		}
	}

	handleResendOtpSuccessfulCallback = (responseJson: any) => {
		if (responseJson.meta && responseJson.meta.token) {
			this.otpToken = responseJson.meta.token;

			this.setState({ token: responseJson.meta.token });

			this.handleShowNotification();
			this.setState({
				otpMessage: configJSON.otpResendText,
				isOtpErrorMessage: false, otp: Array(numberOfDigits).fill(""),
				activeBtn: false,
			});
		}
	}

	handleContinue = () => {
		const message = new Message(getName(MessageEnum.NavigationMessage));
		message.addData(getName(MessageEnum.NavigationTargetMessage), "NewPassword");
		message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
		this.send(message);
	}

	closeModal = () => {
		const message = new Message(getName(MessageEnum.NavigationMessage));
		message.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
		message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
		this.send(message);
	}

	handleShowNotification() {
		this.setState({ otpMessage: configJSON.otpResendText, isOtpErrorMessage: false });
		toast.dismiss();
		toast(
			<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
				<div style={{ marginRight: '16px' }}>
					<span>{`${configJSON.otpSentToEmailText}`} <strong>{this.state.email}</strong></span>
				</div>
				<img data-test-id="close-notification" style={{ width: '24px', height: '24px', cursor: 'pointer' }} onClick={() => toast.dismiss()} src={closeSmallIcon} alt="close" />
			</div>,
			{
				position: "top-left",
				closeButton: false,
				className: '',
				icon: false,
				hideProgressBar: true,
				style: {
					backgroundColor: '#F0FFF8',
					color: '#1e293b',
					fontSize: '16px',
					borderLeft: '5px solid #059669',
					padding: '12px',
					boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
					marginTop: '10%',
					marginLeft: '5%',
					minWidth: 'max-content',
				},
			}
		);
	}

	// async componentDidMount() {
	// 	if (!(this.state.token && this.state.token.length > 0)) {
	// 		this.props.navigation.navigate('ForgotPassword');
	// 	}
	// }

	// async componentDidUpdate() {
	// 	if (!(this.state.token && this.state.token.length > 0)) {
	// 		this.props.navigation.navigate('ForgotPassword');
	// 	}
	// }
}
