export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");

export const AppLogo = require('../assets/Logo.png');
export const FormLogo = require('../assets/FormLogo.png')
export const helpIcon = require('../assets/helpIcon-2.png');
export const unCheckBox = require('../assets/un-checkbox.png');
export const tickCheckBox = require('../assets/tick-checkbox.png');
export const greenCheckCircle = require('../assets/greenCheckCircle.png');
export const blankCircle = require('../assets/blankCircle.png');
export const closeSmallIcon = require('../assets/close.svg').default;
export const closeIcon = require("../assets/close-red.svg").default;
export const successIcon = require("../assets/success.svg").default;
export const circleTick = require("../assets/circleTick.png")