export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const AppLogo = require("../assets/Logo.png")
export const AppLogo2 = require("../assets/Logo2.png")
export const helpIcon1 = require("../assets/helpIcon-1.png")
export const helpIcon2 = require("../assets/helpIcon-2.png")
export const blankCircle = require("../assets/blankCircle.png")
export const greenCheckCircle = require("../assets/greenCheckCircle.png")
export const unCheckBox = require("../assets/un-checkbox.png")
export const tickCheckBox = require("../assets/tick-checkbox.png")
export const circleTick = require("../assets/circleTick.png")