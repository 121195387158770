import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import React from "react";
import { Message } from "../../../framework/src/Message";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  togglePasswordView: boolean;
  password: string;
  passwordValidate: boolean;
  passwordLowerCaseValidate: boolean;
  passwordCapitalCaseValidate: boolean;
  passwordOneNumberValidate: boolean;
  passwordEightDigitValidate: boolean;
  isModalOpen: boolean;
  isAccepted: boolean;
  isScrollCompleted: boolean;
  isChecked: boolean;
  firstName: string;
  lastName: string;
  email: any;
  isFormValid: boolean;
  termsPolicyData: string;
  isCreateAccount: boolean;
}

export interface SS {
  id: any;
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {

  scrollRef: React.RefObject<unknown>;
  apiCreateAccount: string = '';
  apiPrivatePolicy: string = '';

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      togglePasswordView: false,
      password: '',
      passwordValidate: false,
      passwordLowerCaseValidate: false,
      passwordCapitalCaseValidate: false,
      passwordOneNumberValidate: false,
      passwordEightDigitValidate: false,
      isModalOpen: false,
      isAccepted: false,
      isScrollCompleted: false,
      isChecked: false,
      firstName: '',
      lastName: '',
      email: '',
      isFormValid: false,
      termsPolicyData: '',
      isCreateAccount: false,
    };

    this.scrollRef = React.createRef();
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.handleSignUpCall(apiRequestCallId, responseJson)
      this.handleTermsPolicy(apiRequestCallId, responseJson)
    }
  }

  async componentDidMount() {
    this.getTermsCondition();

    const searchParams = new URLSearchParams(window?.location?.search);
    const firstName = searchParams.get('first_name');
    const lastName = searchParams.get('last_name');
    const email = searchParams.get('email');
    this.setState({ firstName: firstName ?? "", lastName: lastName ?? "", email: email ?? ""});
  }
  
  handleSignUpCall = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.apiCreateAccount) {
      if (responseJson?.meta?.token) {
        this.setState({ isCreateAccount: true })
      } else if (responseJson?.errors[0]?.account === 'Email invalid Or Already taken') {
        toast.error(
          "Email invalid Or Already taken",
          { position: toast.POSITION.TOP_LEFT }
        )
      }
    }
  }

  handleTermsPolicy = (apiRequestCallId: any, responseJson: any) => {
    if (this.apiPrivatePolicy === apiRequestCallId) {
      this.setState({ termsPolicyData: responseJson?.data[0]?.description })
    }
  }

  validatePasswordLowerCase(password: string) {
    let hasLowercase = false;
    for (const iterator of password) {
      if (iterator >= 'a' && iterator <= 'z') {
        hasLowercase = true;
      }

      if (hasLowercase) {
        return true;
      }
    }
    return false;
  }

  validatePasswordUpperCase(password: string) {
    let hasUppercase = false;

    for (const iterator of password) {
      if (iterator >= 'A' && iterator <= 'Z') {
        hasUppercase = true;
      }

      if (hasUppercase) {
        return true;
      }
    }
    return false;
  }

  validatePasswordRegex = (passwordTest: string) => {
    let flag = false;
    if (this.validatePasswordLowerCase(passwordTest)) {
      this.setState({ passwordLowerCaseValidate: true, })
    } else {
      this.setState({ passwordLowerCaseValidate: false, })
      flag = true
    }

    if (this.validatePasswordUpperCase(passwordTest)) {
      this.setState({ passwordCapitalCaseValidate: true, })
    } else {
      this.setState({ passwordCapitalCaseValidate: false })
      flag = true
    }

    if (configJSON.oneNumberValidate.test(passwordTest)) {
      this.setState({ passwordOneNumberValidate: true, })
    } else {
      this.setState({ passwordOneNumberValidate: false, })
      flag = true
    }

    if (passwordTest.match(configJSON.allPasswordValidate)) {
      this.setState({ passwordEightDigitValidate: true })
    } else {
      this.setState({ passwordEightDigitValidate: false, })
      flag = true;
    }
    return flag;
  }

  handleTogglePassword = () => {
    this.setState({ togglePasswordView: !this.state.togglePasswordView })
  }

  togglePasswordType = () => {
    return this.state.togglePasswordView ? 'password' : 'text'
  }

  handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.renderIsCheckedValidForm(event)
    this.setState({ password: event.target.value, })
    this.validatePasswordRegex(event.target.value)
  }


  handleScroll = () => {
    const box: HTMLElement | null = this.scrollRef.current as HTMLElement;
    const scrollTop = Math.round(box.scrollTop)
    if (box && (box.scrollHeight - scrollTop === box.clientHeight) || (box.scrollHeight - scrollTop >= box.clientHeight - 1) || (box.scrollHeight - scrollTop <= box.clientHeight + 1)) {
      this.setState({ isScrollCompleted: true });
    }
  };

  closeModal = () => {
    this.setState({ isModalOpen: false, isScrollCompleted: false });
  }

  handleAccept = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!this.state.isScrollCompleted) {
      return (
        toast.error(
          "Need to scroll down to accept Privacy Policy and Terms and Conditions",
          { position: toast.POSITION.TOP_LEFT }
        )
      )
    }
    this.setState({ isAccepted: true });
    this.setState({ isChecked: true });
    this.setState({ isModalOpen: false });
  };

  handleCheckedValidate = () => {
    return !this.state.firstName.length || !this.state.lastName.length || !this.state.email.length || !this.state.password.length || !this.state.passwordCapitalCaseValidate || !this.state.passwordEightDigitValidate || !this.state.passwordLowerCaseValidate || !this.state.passwordOneNumberValidate || !this.state.isChecked
  }

  renderIsCheckedValidForm = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value.length) {
      this.setState({ isFormValid: false, })
    }
  }

  handleDisabledAccountBtn = () => {
    return this.handleCheckedValidate() ? true : false
  }

  returnPasswordType = () => {
    return this.state.togglePasswordView ? "text" : "password"
  }

  handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.renderIsCheckedValidForm(event)
    this.setState({ firstName: event.target.value });
  };

  handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.renderIsCheckedValidForm(event)
    this.setState({ lastName: event.target.value });
  };

  handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.renderIsCheckedValidForm(event)
    this.setState({ email: event.target.value });
  };

  handleCheckboxChange = (e: React.MouseEvent<{}>) => {
    this.setState({ isModalOpen: true });
  };

  isValidateCreateAccountSignUp = () => {
    if (this.validatePasswordRegex(this.state.password) && this.handleCheckedValidate() && !this.state.isChecked) {
      return true
    }
  }

  handleGoToLogin = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
    message.addData(getName(MessageEnum.NavigationPropsMessage), { id: this.props.id, navigation: this.props.navigation });
    this.send(message);
  }

  createAccountSignUp = () => {
    if (this.isValidateCreateAccountSignUp()) {
      this.setState({ isFormValid: false })
    }

    const header = {
      "Content-Type": "application/json"
    };

    const httpBody = {
      "data": {
        "type": "email_account",
        "attributes": {
          "first_name": this.state.firstName,
          "last_name": this.state.lastName,
          "email": this.state.email,
          "password": this.state.password
        }
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCreateAccount = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.signupEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  getTermsCondition = () => {
    const header = {
      "Content-Type": "application/json"
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiPrivatePolicy = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.termsAndConditionUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

}
