import React, { FunctionComponent, ReactNode, useEffect, useState } from "react";
import {
    Box,
    styled,
    List,
    ListItem,
    ListItemText,
    Collapse,
    Avatar,
} from "@mui/material";
import { getStorageData, setStorageData } from "framework/src/Utilities";

interface Props {
    children: ReactNode;
    currentRoute?: string;
    navigation: any;
}

const Layout: FunctionComponent<Props> = (props) => {
    const [isAdminOpen, setAdminOpen] = useState((props?.currentRoute === "Administration" || props?.currentRoute === "Manage Users"));
    const [isAdmin, setIsAdmin] = useState(false);
    const toggleAdminMenu = () => setAdminOpen(!isAdminOpen);

    useEffect(() => {
        getStorageData("role").then((role) => {
            if (role === "Administrator") {
                setIsAdmin(true)
            };
        });
    }, []);

    return (
        <Container>
            <div className="sideBar">
                <img
                    src={require("./sideBarLogo.png").default}
                    style={{
                        width: "118px",
                        objectFit: "contain",
                        marginBlock: "30px",
                    }}
                    alt="Logo"
                />
                <List className="menuList">
                    {menuItems.filter(e => isAdmin || e.label !== "Administration").map((item, index) =>
                        item.children ? (
                            <div key={index}>
                                <ListItem
                                    button
                                    className="menuItem"
                                    onClick={toggleAdminMenu}
                                >
                                    {item.icon}
                                    <ListItemText primary={item.label} />
                                    <img src={require("./ic_dropdown.png").default} style={{width: "24px", height: "24px", transform: isAdminOpen
  ? "rotate(0deg) translateX(1px)"
  : "rotate(180deg) translateX(-1px)" }} />
                                   {(props?.currentRoute === "Administration" || props?.currentRoute === "Manage Users") && <div className="menuFocused"/>}
                                </ListItem>
                                <Collapse in={isAdminOpen} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {item.children.map((child, childIndex) => (
                                            <ListItem
                                                button
                                                key={childIndex}
                                                className="menuItem childItem"
                                                style={child.label === props.currentRoute ? {backgroundColor: "#0C4387" } : {}}
                                            >
                                                {child.icon}
                                                <ListItemText primary={child.label} style={{
                                                    fontWeight: 400,
                                                    fontSize: "14px",
                                                }} />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Collapse>
                            </div>
                        ) : (
                            <ListItem button key={index} className="menuItem">
                                {item.icon}
                                <ListItemText primary={item.label} />
                            </ListItem>
                        )
                    )}
                </List>
                <div className="userInfo">
                    <Avatar className="avatar">CL</Avatar>
                    <Box className="userDetails">
                        <p className="userName">Carlos Loaiza</p>
                        <p className="userOrg">Organization</p>
                    </Box>
                    <Box onClick={() => {
                        setStorageData("token", undefined);
                        props.navigation.navigate("EmailAccountLoginBlock");
                    }} style={{display: "flex", flexDirection: "row", alignItems: "center", gap: "10px", cursor: "pointer"}}>
                        <img src={require("./ic_logout.png").default} style={{ width: "24px", height: "24px" }} />
                        <p className="logout" style={{fontSize: "16px", fontWeight: 400, color: "white"}}>log out</p>
                    </Box>
                </div>
            </div>
            <div className="content">{props.children}</div>
        </Container>
    );
};

export default Layout;

// Sidebar items
const menuItems = [
    { label: "Home", icon: <img src={require("./ic_home.png").default} style={{ width: "24px", height: "24px" }} /> },
    { label: "Settings", icon: <img src={require("./ic_settings.png").default} style={{ width: "24px", height: "24px" }} /> },
    { label: "Help Center", icon: <img src={require("./ic_help.png").default} style={{ width: "24px", height: "24px" }} /> },
    { label: "Notifications", icon: <img src={require("./ic_noti.png").default} style={{ width: "24px", height: "24px" }} /> },
    { label: "Bots", icon: <img src={require("./ic_bot.png").default} style={{ width: "24px", height: "24px" }} /> },
    { label: "Campaigns", icon: <img src={require("./ic_campaign.png").default} style={{ width: "24px", height: "24px" }} /> },
    {
        label: "Administration",
        icon: <img src={require("./ic_admin.png").default} style={{ width: "24px", height: "24px" }} />,
        children: [
            {
                label: "Manage Users",
                icon: <img src={require("./ic_manage_user.png").default} style={{ width: "24px", height: "24px" }} />,
            },
            {
                label: "Billing",
                icon: <img src={require("./ic_billing.png").default} style={{ width: "24px", height: "24px" }} />,
            },
        ],
    },
    { label: "Contact Us", icon: <img src={require("./ic_contact_us.png").default} style={{ width: "24px", height: "24px" }} /> },
];

// Styled components
const Container = styled(Box)({
    width: "100vw",
    height: "100%",
    display: "flex",

    "& .sideBar": {
        width: "235px",
        height: "100%",
        backgroundColor: "#002553",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px 0px",
        boxSizing: "border-box"
    },

    "& .menuList": {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        marginTop: "20px"
    },

    "& .menuFocused": {
        width: "8px",
        height: "100%",
        background: "linear-gradient(180deg, #FFFFFF 0%, #C1C1C1 100%)",
        position: "absolute",
        right: "0%"
    },

    "& .menuItem": {
        color: "#fff",
        padding: "10px 20px",
        display: "flex",
        alignItems: "center",
        gap: "10px",
        "&:hover": {
            backgroundColor: "#013a73",
        },

        "& .MuiTypography-root": {
            fontWeight: 700,
            fontSize: "16px"
        },

        "& .childItem": {
            fontWeight: 400,
            fontSize: "14px",
        }
    },

    "& .menuItem.childItem .MuiTypography-root": {
    fontWeight: 400, // Override for child items
    fontSize: "14px",
    },

    "& .childItem": {
       fontWeight: 400,
       fontSize: "14px",

        "&:hover": {
            backgroundColor: "#012a50",
        },
    },

    "& .userInfo": {
        fontFamily: "Roboto, Helvetica, Arial, sans-serif;",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        marginTop: "40px",
        height: "100%",
        justifyContent: "end",
        paddingBottom: "30px",
        boxSizing: "border-box",

        "& .avatar": {
            background: "linear-gradient(180deg, #FFFFFF 0%, #C1C1C1 100%)",
            width: "48px",
            height: "48px",
            color: "#00184C"
        },
        "& .userDetails": {
            "& .userName": {
                fontSize: "16px",
                color: "#fff",
                fontWeight: 700,
            },
            "& .userOrg": {
                fontSize: "12px",
                fontWeight: 400,
                color: "#E4C9F3",
            },
        },
        "& .logout": {
            color: "white",
            cursor: "pointer",
        },
    },

    "& .content": {
        flexGrow: 1,
        backgroundColor: "#E9E9EA",
        padding: "20px",
        height: "100%",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        flex: 1
    },
});
